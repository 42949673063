<form #itemForm= "ngForm">
  <div class="wholeItem">
    <div (click)="toggleDiv()" class="wholeLabel" [ngClass]="{'itemDataActive': isVisible, 'itemOk': isOk, 'itemNok': !isOk}">
      <img class="centerItem" (click)="centerItem()" src="../assets/center.png">
      <label>{{itemName}}</label>
      <img class="deleteItem" (click)="removeItem()" src="../assets/remove.png">
    </div>
    <div class="itemData" [ngClass]="{'itemDataHide': !isVisible}">
      <div class="form-group">
        <div class="controlLabel">
          <label>Name:</label>
        </div>
        <div class="controlInput">
          <input type="text" name="itemName" class="form-control" [(ngModel)]="item.name" (ngModelChange)="onUpdateObject()">
        </div>
      </div>
      <div class="form-group">
        <div class="controlLabel">
          <label>Type:</label>
        </div>
        <div class="controlInput">
          <select #itemType name="itemType" class="form-select" id="itemType" [(ngModel)]="trampolineSelectType" (ngModelChange)="onUpdateSelectedType()">
              <option *ngFor="let enum of TrampolineTypes | keyvalue" [value]="enum.value">{{enum.value}}</option>
          </select>
        </div>
      </div>
      <div class="form-group">
        <div class="controlLabel">
          <label>Model:</label>
        </div>
        <div class="controlInput">
          <select (change)="onModelChange($event)" class="form-select" name="trampolineModel" id="trampolineModel">
            <option *ngFor="let row of csvDataModel" [value]="row['Typ']">{{row['Typ']}}</option>
          </select>
        </div>
      </div>
      <div class="dimensions form-group" *ngIf="!trampolineModel.B">
        <div class="controlLabel dimensions">
          <label for="circleDimensions">Diameter:</label>
          <img class="refresDim" (click)="refreshDimItem()" src="../assets/refresh.png">
        </div>
        <div class="parameterSegment">
          <div class="controlInput">
            <input [readOnly]="false" type="number" step="0.01" id="circleDimensions" class="form-control" name="circleDimensions" [(ngModel)]="dimA" (ngModelChange)="onUpdateObject()" min="1"/>
          </div>
          <span>m</span>
        </div>
      </div>
      <div class="dimensions form-group" *ngIf="trampolineModel.B">
        <div class="controlLabel dimensions">
          <label for="rectangleWidth">Dimensions:</label>
          <img class="refresDim" (click)="refreshDimItem()" src="../assets/refresh.png">
        </div>
        <label for="rectangleWidth">Width:</label>
        <div class="parameterSegment">
          <div class="controlInput">
            <input [readOnly]="false" type="number" step="0.01" id="rectangleWidth" class="form-control" name="rectangleWidth" [(ngModel)]="dimA" (ngModelChange)="onUpdateObject()" min="1"/>
          </div>
          <span>m</span>
        </div>
        <label for="rectangleHeight">Height:</label>
        <div class="parameterSegment">
          <div class="controlInput">
            <input [readOnly]="false" type="number" step="0.01" id="rectangleHeight" class="form-control" name="rectangleHeight" [(ngModel)]="dimB" (ngModelChange)="onUpdateObject()" min="1"/>
          </div>
          <span>m</span>
        </div>
      </div>
      <div class="form-group" *ngIf="!trampolineModel.B && item.type !== TrampolineTypes.WPCircle">
        <div class="controlLabel">
          <label for="circleDimensions">Center color:</label>
        </div>
        <div class="colorSelection controlInput">
          <select (change)="onCenterColorChange($event)" name="centerColorSelector" class="form-select" id="centerColorSelector" [ngStyle]="{'background-color': selectedCenterColor || 'white'}">
            <option disabled selected [value]="">Select color</option>
            <option *ngFor="let row of csvData" [style.background-color]="row['HEX']" [value]="row['HEX']">{{row['RAL']}}</option>
          </select>
        </div>
      </div>
      <div class="form-group">
        <div class="controlLabel">
          <label>Colors:</label>
        </div>
        <!--<input type="number" name="itemCount" [(ngModel)]="item.colorCount" (ngModelChange)="onUpdateObject()" min="1"/>-->
        <div id="colorsSelection" class="controlInput">
          <div class="colorSelect" *ngFor="let color of getNumberArray(trampolineModel.count); let i = index">
            <select (change)="onColorChange($event, i)" [name]="'colorSelector' + i" class="form-select" [id]="'colorSelector' + i" [ngStyle]="{'background-color': selectedColors[i] || 'white'}">
              <option disabled selected [value]="">Select color</option>
              <option *ngFor="let row of csvData" [style.background-color]="row['HEX']" [value]="row['HEX']">{{row['RAL']}}</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>

