import { Component, OnInit, ElementRef, ViewChild, AfterViewInit, NgZone } from '@angular/core';
import { GoogleMap, MapPolygon, MapMarker, MapAdvancedMarker } from '@angular/google-maps';
import { GeocodingService } from '../geocoding.service';
import { MapInfoWindow } from '@angular/google-maps';
import { CircleDimesions, RectangleDimensions, Trampoline, TrampolineType } from '../item-list/trampoline';
import { ShareDataService } from '../share-data.service';
import { empty, map, Subscription } from 'rxjs';

interface PolygonData {
  polygon: google.maps.Polygon | google.maps.Circle;
  originalCoords: google.maps.LatLngLiteral[],
  originalAngles: number[]
}
interface TrampolineData {
  trampoline: Trampoline,
  polygons: PolygonData[],
  marker: google.maps.marker.AdvancedMarkerElement | null,
  markerLastPos: google.maps.LatLngLiteral | null,
  centerCircle: google.maps.Circle | null,
  dropZone: google.maps.Circle | google.maps.Polygon | null
  dropZoneOriginalCoords: google.maps.LatLngLiteral[] | null,
  currentAngle: number
}

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrl: './map.component.scss',
})
export class MapComponent implements OnInit, AfterViewInit {
  @ViewChild('searchText') searchText!: ElementRef;
  @ViewChild('mapContainer', { static: false }) mapContainer!: ElementRef;
  map!: google.maps.Map;
  centerChangedTimeout: any; //timeout for map center change
  subscription: Subscription = new Subscription;

  originalAngles: number[] = [];
  trampoline: TrampolineData[] = [];

  dropZoneInMeters = 2;
  dragStart: boolean = false;

  zoom = 8;
  center!: google.maps.LatLngLiteral;
  options: google.maps.MapOptions = {
    mapTypeId: 'hybrid',
    mapId: '14b5d89362793693',
    zoomControl: true,
    scrollwheel: true,
    disableDoubleClickZoom: true,
    //maxZoom: 28,
    rotateControl: true,
  };

  constructor(private geocodingService: GeocodingService ,private ngZone: NgZone, private dataService: ShareDataService){
  }
  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.subscription.remove;
  }


  async initMap(): Promise<void> {
    const { Map } = await google.maps.importLibrary("maps") as google.maps.MapsLibrary;
    const { AdvancedMarkerElement } = await google.maps.importLibrary("marker") as google.maps.MarkerLibrary;

    //console.log(AdvancedMarkerElement);
      this.map = new Map(document.getElementById("map") as HTMLElement, {
        center: this.center,
        zoom: this.zoom,
        mapId: 'ba5bc7dad11105e5', //'14b5d89362793693',ba5bc7dad11105e5
        mapTypeId: 'hybrid',
        zoomControl: true,
        scrollwheel: true,
        disableDoubleClickZoom: true,
        rotateControl: true,
      });
      this.map.addListener('center_changed',() => {
        this.scheduleCenterChanged();
      });
  }

  ngAfterContentInit(): void {
  }

  ngOnInit(): void {
    this.initMap();
    this.center = {
      lat: 50.0753142,
      lng: 14.4376958
    };

    //this.map.setOptions({zoom: 20});
  }

  async ngAfterViewInit() {

    this.dataService.centerIndex$.subscribe(data => {
      if(this.trampoline.length > data) {
        this.center = {
          lat: this.trampoline[data].marker!.position!.lat as number,
          lng: this.trampoline[data].marker!.position!.lng as number,
        };
        this.map.setCenter(this.center);
        //this.trampoline[data].marker!.position = this.center;
        //this.changeMarkerCenter(this.center.lat, this.center.lng, this.trampoline[data].marker!);
        if(this.map.getZoom()! < 20)
          this.map.setOptions({zoom: 20});
      }
    });

    this.subscription = this.dataService.data$.subscribe(data => {

      if(Array.isArray(data) && data.length > 0)
      {
        //remove old data

        const newNames = new Set(data.map(item => item.id));
        let indexCount = 0;
        const itemToRemove = this.trampoline.filter(item => !newNames.has(item.trampoline.id));
        if(this.trampoline.length > 0)
          {
            for (let index = 0; index < data.length; index++)
            {
              if(data[index].id == null)
                itemToRemove.push(this.trampoline[index]);
            }
          }
        //console.log("pam component");
        //console.log(itemToRemove);
        itemToRemove.forEach(element => {
          if(element.marker)
            (element.marker as google.maps.marker.AdvancedMarkerElement).map = null;
          if(element.polygons.length > 0)
          {
            element.polygons.forEach(item => {
              item.polygon.setMap(null);
            });
          }
          if(element.centerCircle)
            element.centerCircle?.setMap(null);
          if(element.dropZone)
            element.dropZone?.setMap(null);
        });
        this.trampoline = this.trampoline.filter(item => newNames.has(item.trampoline.id));

        //add or update items
        data.forEach(newItem => {
          if(newItem.id !== null)
          {
            const a = data.filter((v) => v.name === newItem.name).length;
            if(a > 1)
            {
              newItem.name = newItem.name + "-" + newItem.id;
            }
            const existingIndex = this.trampoline.findIndex(item => item.trampoline.id === newItem.id);
            if(existingIndex !== -1){
              this.trampoline[existingIndex].marker!.title = newItem.name;
              this.trampoline[existingIndex].trampoline = newItem;
            }
            else {
              indexCount++;
              this.trampoline.push({
                trampoline: newItem,
                polygons: [],
                marker: null,
                markerLastPos: null,
                centerCircle: null,
                dropZone: null,
                dropZoneOriginalCoords: null,
                currentAngle: 0
              });
            }
          }
        });
        this.newDataArrived(indexCount);
      }
      else
      {
        //remove all
        this.trampoline.forEach(element => {
          if((element.marker as google.maps.marker.AdvancedMarkerElement).map)
            (element.marker as google.maps.marker.AdvancedMarkerElement).map = null;
          if(element.polygons.length > 0)
          {
            element.polygons.forEach(item => {
              item.polygon.setMap(null);
            });
          }
          if(element.centerCircle)
            element.centerCircle?.setMap(null);
          if(element.dropZone)
            element.dropZone?.setMap(null);
        });
        this.trampoline = [];
      }
    });

    navigator.geolocation.getCurrentPosition((position) => {
      this.center = {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      };
    });
    this.options.center = this.center;

  //async  initMap(): Promise<void> {

    //Called after ngOnInit when the component's or directive's content has been initialized.
    //Add 'implements AfterContentInit' to the class.
    await this.initSearch();
  }

  newDataArrived(indexCount: number)
  {
    if(this.trampoline !== undefined && this.trampoline !== null)
    {
      if(indexCount > 0 && (this.map.getZoom()! < 20))
        this.map.setOptions({zoom: 20});
      for (let index = this.trampoline.length - indexCount; index < this.trampoline.length; index++)
      {
        if(this.trampoline[index].trampoline.id !== null)
        {
          this.addMarker(this.center.lat, this.center.lng, index);
          if(this.trampoline[index].trampoline.type === TrampolineType.Circle)
            this.createCirclePolygon(this.trampoline[index], true);
          else if(this.trampoline[index].trampoline.type === TrampolineType.Rectangle)
            this.createRectangle(this.trampoline[index], true);
          else if(this.trampoline[index].trampoline.type === TrampolineType.WPCircle)
            this.createWPCirclePolygon(this.trampoline[index], true);
          else if(this.trampoline[index].trampoline.type === TrampolineType.WPRectangle)
            this.createWPRectangle(this.trampoline[index], true);
        }
      }
      //redraw existing items
      for(let index = 0; index < this.trampoline.length - indexCount; index++)
      {
        if(this.trampoline[index].trampoline.id !== null)
        {
          if(this.trampoline[index].trampoline.type === TrampolineType.Circle)
            this.createCirclePolygon(this.trampoline[index], false);
          else if(this.trampoline[index].trampoline.type === TrampolineType.Rectangle)
            this.createRectangle(this.trampoline[index], false);
          else if(this.trampoline[index].trampoline.type === TrampolineType.WPCircle)
            this.createWPCirclePolygon(this.trampoline[index], false);
          else if(this.trampoline[index].trampoline.type === TrampolineType.WPRectangle)
            this.createWPRectangle(this.trampoline[index], false);
        }
      }
    }
  }

  handleCenterItem(event: Event){
    //console.log(event.target);
    //this.map.setCenter =
  }

  async initSearch(){

    const options = {
      fields: ["formatted_address", "geometry", "name"],
      strictBounds: false,
    };
    const { Place, AutocompleteSessionToken } = await google.maps.importLibrary("places") as google.maps.PlacesLibrary;
    const token = new AutocompleteSessionToken();
    this.searchText.nativeElement.AutocompleteSessionToken = token
    //const {Place} = await google.maps.importLibrary("places");
    const searchTexts = await new google.maps.places.Autocomplete(this.searchText.nativeElement);
    //searchTexts.bindTo("bounds", this.map);
    //const searchTexts = await Place.searchByText(this.searchText.nativeElement);
    //map.controls[google.maps.ControlPosition.TOP_LEFT].push(this.searchText.nativeElement);
    /*this.center = { lat: this.map.getCenter()?.lat() as number,
                    lng: this.map.getCenter()?.lng() as number
    };*/

    //map.controls[google.maps.ControlPosition.TOP_LEFT].push(this.searchText.nativeElement);
    /*this.center = { lat: this.map.getCenter()?.lat() as number,
                    lng: this.map.getCenter()?.lng() as number
    };*/

    searchTexts.addListener('place_changed', () => {

      this.ngZone.run(() => {
        let place: google.maps.places.PlaceResult = searchTexts.getPlace();

        if(place.geometry === undefined ||
           place.geometry === null
        )
          return;
        if(place.geometry.location?.lat() !== undefined && place.geometry.location?.lng() !== undefined)
        {
          this.center = {
            lat: place.geometry.location?.lat(),
            lng: place.geometry.location?.lng()
          };
          if(this.map.getZoom()! < 18)
            this.map.setOptions({zoom: 18});
        }
      });
    });
  }

  handleEnter(): void {
    const input = this.searchText.nativeElement.value;
    this.geocodingService.geocodeAdress(input).subscribe((response: any) => {
      if(response.status === 'OK')
      {
        const location = response.results[0].geometry.location;
        this.ngZone.run(() => {
          this.center = {
            lat: location.lat,
            lng: location.lng
          }
          this.map.setCenter(this.center);
          if(this.map.getZoom()! < 18)
            this.map.setOptions({zoom: 18});
        });
      }
      else
      {
        const coords = this.parseCoordinates(input);
        if(coords){
          this.center = coords;
        }
      }
    });
  }

  parseCoordinates(input: string): google.maps.LatLngLiteral | null {
    const parts = input.split(',');
    if(parts.length !== 2)
      return null;

    const lat = parseFloat(parts[0].trim());
    const lng = parseFloat(parts[1].trim());

    if(isNaN(lat) || isNaN(lng))
      return null;
    return { lat, lng };
  }

  onMapInitialized(map: google.maps.Map){
    this.map = map;
    this.map.addListener('center_changed',() => {
      this.scheduleCenterChanged();
    });

    //this.addMarker();
  }

  addMarker(lat: number, lng: number, index: number){
    /*const marker = new google.maps.Marker({
      position: this.center,
      map: this.map,
      title: "item 0"
    });*/
    if(lat !== undefined && lng !== undefined && lat !== null && lng !== null)
    {
      const markerElement = document.createElement('div');
      markerElement.className = 'mapMarker';
      markerElement.style.width = '28px';
      markerElement.style.height = '28px';
      markerElement.style.background = '#b89128';
      markerElement.style.borderRadius = '50%';
      //markerElement.style.opacity = '1';
      markerElement.style.position = 'relative';
      markerElement.style.top ='14px';
      markerElement.style.border = '1px';
      markerElement.style.outline = 'none';
      markerElement.style.boxShadow = 'none';

      const infoWindowContent = document.createElement('div');
      infoWindowContent.innerHTML = `<div class="controlInput">
                                      <label>Rotation</label>
                                      <input id="rangeInput${index}" type="range" min="0" max="360" value="0" class="form-range rotationSlider">
                                      <input id="rangeValue${index}" type="number" value="0" class="form-control min="0" max="360">
                                    </div>`

      const infoWindow = new google.maps.InfoWindow({
        content: infoWindowContent
      });
      const rangeInput = infoWindowContent.querySelector('#rangeInput'+index) as HTMLInputElement;
      const rangeValue = infoWindowContent.querySelector('#rangeValue'+index) as HTMLInputElement;

      rangeInput.addEventListener('input', () => {
        this.rotatePolygons(parseInt(rangeInput.value), index);
        //this.rotateShape(parseInt(rangeValue.value), index);
        rangeValue.value = rangeInput.value;
        this.trampoline[index].currentAngle = parseInt(rangeInput.value);
      });

      rangeValue.addEventListener('input', () => {
        if(typeof +rangeValue.value === "number" && !isNaN(+rangeValue.value) && rangeValue.value.length > 0 && rangeValue.value.length !== undefined)
        {
          try{
            const value = parseInt(rangeValue.value);
            this.rotatePolygons(value, index);
            //console.log(value);
            rangeInput.value = rangeValue.value;
            this.trampoline[index].currentAngle = value;
          }
          catch{
            rangeInput.value = '0';
          }
        }
      });

      const marker = new google.maps.marker.AdvancedMarkerElement({
        map: this.map,
        position: { lat: lat, lng: lng },
        gmpDraggable: true,
        title: this.trampoline[index].trampoline.name,
        content: markerElement,
      });
      marker.content!.parentElement!.style.opacity = '0';

      marker.addListener('dragstart',(event: google.maps.MapMouseEvent) => {
          marker.content!.parentElement!.style.opacity = '0.8';
          this.dragStart = true;
      });
      marker.addListener('dragend',(event: google.maps.MapMouseEvent) => {
        this.onMarkerDragEnd(event, marker);
        this.dragStart = false;
        //marker.content!.parentElement!.style.opacity = '0';
      });
      marker.addListener('click',(event: google.maps.MapMouseEvent) => {
        infoWindow.open({
          anchor: marker,
          map: this.map,
          shouldFocus: true
        });
      });
      //marker.setDraggable(true);
      this.trampoline[index].marker = marker;
      this.trampoline[index].markerLastPos = marker.position as google.maps.LatLngLiteral;
    }
  }
  getMarker(index: number): google.maps.marker.AdvancedMarkerElement{
    return this.trampoline[index].marker!;
  }

  onMarkerDragEnd(event: google.maps.MapMouseEvent, marker: google.maps.marker.AdvancedMarkerElement){
    const lat = event.latLng?.lat();
    const lng = event.latLng?.lng();
    this.changeMarkerCenter(lat as number, lng as number, marker);
  }

  changeMarkerCenter(lat: number, lng: number ,marker: google.maps.marker.AdvancedMarkerElement)
  {
    if(lat !== undefined && lng !== undefined)
    {
      const i = this.trampoline.findIndex(item => item.trampoline.name === marker.title);
      const currentPos = this.trampoline[i].markerLastPos;
      const currentLat = currentPos?.lat ?? 0;
      const currentLng = currentPos?.lng ?? 0;
      const latDiff = lat - currentLat;
      const lngDiff = lng - currentLng;
      if(this.trampoline[i].trampoline.type !== TrampolineType.WPCircle)
      {
        for (let index = 0; index < this.trampoline[i].polygons.length; index++) {
          const newPaths = ((this.trampoline[i].polygons[index].polygon as google.maps.Polygon).getPath().getArray() as google.maps.LatLng[]).map(path => ({
            lat: path.lat() + latDiff,
            lng: path.lng() + lngDiff
          }));
          //marker.position = {lat, lng};
          this.trampoline[i].polygons[index].originalCoords = newPaths;
          (this.trampoline[i].polygons[index].polygon as google.maps.Polygon).setPath(newPaths);
        }
      }
      else
      {
        this.trampoline[i].polygons[0].originalCoords[0] = marker.position as google.maps.LatLngLiteral;
        (this.trampoline[i].polygons[0].polygon as google.maps.Circle).setCenter(marker.position as google.maps.LatLngLiteral);
      }
      this.trampoline[i].markerLastPos = marker.position as google.maps.LatLngLiteral;
      if(this.trampoline[i].trampoline.type === TrampolineType.Circle)
      {
        this.trampoline[i].centerCircle?.setCenter(this.trampoline[i].markerLastPos);
        (this.trampoline[i].dropZone as google.maps.Circle)?.setCenter(this.trampoline[i].markerLastPos);
      }
      else if(this.trampoline[i].trampoline.type === TrampolineType.Rectangle)
      {
        const newPaths = ((this.trampoline[i].dropZone as google.maps.Polygon)!.getPath().getArray() as google.maps.LatLng[]).map(path => ({
          lat: path.lat() + latDiff,
          lng: path.lng() + lngDiff
        }));
        this.trampoline[i].dropZoneOriginalCoords = newPaths;
        (this.trampoline[i].dropZone as google.maps.Polygon).setPath(newPaths);
      }
    }
  }

  scheduleCenterChanged(){
    if(this.centerChangedTimeout)
    {
      clearTimeout(this.centerChangedTimeout);
    }
    this.centerChangedTimeout = setTimeout(() => {
      this.center = {
        lat: this.map.getCenter()?.lat() as number,
        lng: this.map.getCenter()?.lng() as number
      };
      //console.log(this.center);
      //this.onCenterChanged();
    }, 500);
  }
  /* onMapClick()
  onMapClick(event: google.maps.MapMouseEvent){
    const lat = event.latLng?.lat();
    const lng = event.latLng?.lng();
    if(lat && lng) {
      for (let index = 0; index < this.trampoline.length; index++)
        {
          this.addMarker(lat, lng, index);
          if(this.trampoline[index].trampoline.type === TrampolineType.Circle)
            this.createCirclePolygon(this.trampoline[index]);
          else
            this.createRectangle(this.trampoline[index]);
        }
    }
  }
  */

  rotatePolygons(degrees: number, i: number){
    if(this.trampoline[i].trampoline.type !== TrampolineType.WPCircle)
    {
      this.trampoline[i].currentAngle = degrees;
      const radians = degrees * Math.PI / 180;
      const center = this.trampoline[i].marker!.position;//originalCoords[0];
      var prj = this.map.getProjection();
      var origin = prj!.fromLatLngToPoint(center!);

      this.trampoline[i].polygons.forEach((polygonData) => {
        const originalCoords = polygonData.originalCoords;
        const rotateCoords = originalCoords.map((vertex) => {
          var point = prj!.fromLatLngToPoint(vertex);
          var rotatedLatLng =  prj!.fromPointToLatLng(this.rotatePoint(point!, origin!, radians));
          return {lat: rotatedLatLng!.lat(), lng: rotatedLatLng!.lng()};
        });
        (polygonData.polygon as google.maps.Polygon).setPath(rotateCoords);
      });
      if(this.trampoline[i].trampoline.type === TrampolineType.Rectangle)
      {
        //rotate dropzone
        const originalCoords = (this.trampoline[i].dropZoneOriginalCoords as google.maps.LatLngLiteral[]);
        const rotateCoords = originalCoords.map((vertex) => {
          var point = prj!.fromLatLngToPoint(vertex);
          var rotatedLatLng =  prj!.fromPointToLatLng(this.rotatePoint(point!, origin!, radians));
          return {lat: rotatedLatLng!.lat(), lng: rotatedLatLng!.lng()};
        });
        (this.trampoline[i].dropZone as google.maps.Polygon).setPath(rotateCoords);
      }
    }
  }

    rotatePoint(point: google.maps.Point, origin: google.maps.Point, angle: number): google.maps.Point
    {
      const x = (Math.cos(angle) * (point.x - origin.x) - Math.sin(angle) * (point.y - origin.y) + origin.x);
      const y = (Math.sin(angle) * (point.x - origin.x) + Math.cos(angle) * (point.y - origin.y) + origin.y);
      const a = new google.maps.Point(x, y);
      return a;
    }

    removeFromMap(trampoline: TrampolineData)
    {
      trampoline.polygons.forEach(item => {
        item.polygon.setMap(null);
      });
      trampoline.centerCircle?.setMap(null);
      trampoline.centerCircle = null;
      trampoline.dropZone?.setMap(null);
      trampoline.dropZone = null;
      //trampoline.dropZoneOriginalCoords = null;
      trampoline.polygons = [];
    }

  createWPCirclePolygon(trampoline: TrampolineData, isNewItem: boolean)
  {
    this.removeFromMap(trampoline);
    const polygonCircle = new google.maps.Circle({
      strokeColor: trampoline.trampoline.colors[0],
      strokeOpacity: 1,
      fillColor: trampoline.trampoline.colors[0],
      fillOpacity: 1,
      map: this.map,
      center: trampoline.marker!.position,
      radius: (trampoline.trampoline.dimensions as CircleDimesions).diameter / 2,
      zIndex: 1,
    });
    if(isNewItem)
    {
      this.setPolygonEvents(polygonCircle, trampoline.marker!);
      trampoline.polygons.push({
        polygon: polygonCircle,
        originalCoords: [trampoline.marker!.position! as google.maps.LatLngLiteral],
        originalAngles: [0]
      });
    }
    else
    {
      trampoline.polygons[0] =
      {
        polygon: polygonCircle,
        originalCoords: [trampoline.marker!.position! as google.maps.LatLngLiteral],
        originalAngles: [0]
      };
    }
    polygonCircle.setMap(this.map);
  }

  createCirclePolygon(trampoline: TrampolineData, isNewItem: boolean){
    //this.addMarker();
    const angleStep = 360 / trampoline.trampoline.colors.length;
    this.removeFromMap(trampoline);
    for (let index = 0; index < trampoline.trampoline.colors.length; index++)
    {
      const paths: google.maps.LatLngLiteral[] = [];

      const angle1 = index * angleStep;
      const angle2 = angleStep * (index +1);

      const startAngle = (index * 360 / trampoline.trampoline.colors.length) * Math.PI / 180;
      const dim: CircleDimesions = trampoline.trampoline.dimensions as CircleDimesions;

      const point1 = trampoline.marker!.position;
      const point2 = this.calculatePoint(dim.diameter/2, angle1, point1 as google.maps.LatLngLiteral);
      const point3 = this.calculatePoint(dim.diameter/2, angle2, point1 as google.maps.LatLngLiteral);

      //const angles: number[] = [startAngle, startAngle + (2 * Math.PI / trampoline.colors.length), startAngle + (2 * 2 * Math.PI / trampoline.colors.length)];
      const angles: number[] = [angle1* Math.PI / 180, angle1* Math.PI / 180, angle2* Math.PI / 180];
      if(point1 !== undefined && point1 !== null)
      {
        //paths.push([point1 as google.maps.LatLngLiteral, point2, point3]);
        paths.push(point1 as google.maps.LatLngLiteral);
        paths.push(point2);
        paths.push(point3);
      }

      const polygon = new google.maps.Polygon({
        paths: paths,
        strokeColor: trampoline.trampoline.colors[index],
        strokeOpacity: 1,
        fillColor: trampoline.trampoline.colors[index],
        fillOpacity: 1,
        strokeWeight: 0.25,
        zIndex: 2,
      });
      if(isNewItem)
      {
        this.setPolygonEvents(polygon, trampoline.marker!);

        trampoline.polygons.push({
          polygon: polygon,
          originalCoords: paths,
          originalAngles: angles
        });
      }
      else
      {
        trampoline.polygons[index] =
        {
          polygon: polygon,
          originalCoords: paths,
          originalAngles: angles
        };
      }
      polygon.setMap(this.map);
    }
    this.generateCenter(trampoline);
    this.generateDropZoneCircle(trampoline);
    if(!isNewItem)
    {
      this.rotatePolygons(trampoline.currentAngle, this.trampoline.findIndex(item => item.trampoline.id === trampoline.trampoline.id));
    }
  }

  generateCenter(trampoline: TrampolineData){
    const centerCircle = new google.maps.Circle({
      strokeColor: trampoline.trampoline.centerColor,
      strokeOpacity: 1,
      fillColor: trampoline.trampoline.centerColor,
      fillOpacity: 1,
      map: this.map,
      center: trampoline.marker!.position,
      radius: 0.5,
      zIndex: 4,
    });
    this.setPolygonEvents(centerCircle, trampoline.marker!);
    trampoline.centerCircle = centerCircle;
  }

  generateDropZoneCircle(trampoline: TrampolineData)
  {
    const dropZoneCircle = new google.maps.Circle({
      strokeColor: 'black',
      strokeWeight: 0.75,
      strokeOpacity: 0.75,
      fillColor: 'white',
      fillOpacity: 0.1,
      map: this.map,
      center: trampoline.marker!.position,
      radius: (trampoline.trampoline.dimensions as CircleDimesions).diameter / 2 + this.dropZoneInMeters,
      zIndex: 1,
    });
    trampoline.dropZone = dropZoneCircle;
  }

  calculatePoint(radiusMeters: number, angleDegrees: number, centerPos: google.maps.LatLngLiteral): google.maps.LatLngLiteral{
    const angleRad = angleDegrees * (Math.PI / 180);

    const latOffset = radiusMeters * Math.cos(angleRad) / 111320;
    const lngOffset = radiusMeters * Math.sin(angleRad) / (111320 * Math.cos(this.center.lat * Math.PI / 180));

    return {
      lat: centerPos.lat + latOffset,
      lng: centerPos.lng + lngOffset
    };
  }

  //createRectangle(height: number, width: number)
  createRectangle(trampoline: TrampolineData, isNewItem: boolean)
  {
    const center = trampoline.marker!.position;
    const dim: RectangleDimensions = trampoline.trampoline.dimensions as RectangleDimensions;
    const segmentWidthMeters = dim.width / trampoline.trampoline.colors.length;
    const halfHeight = dim.height / 2;

    this.removeFromMap(trampoline);
    if(trampoline.trampoline.type === TrampolineType.Rectangle)
      this.generateDropZoneRectangle(trampoline);
    for (let index = 0; index < trampoline.trampoline.colors.length; index++)
    {
      const startX = this.lngOffset((center?.lng as number), segmentWidthMeters * index - dim.width / 2, (center?.lat as number));//(center?.lng as number) - (dim.width / 2) + (index * segmentWidth);
      const endX = this.lngOffset((center?.lng as number), segmentWidthMeters * (index + 1) - dim.width / 2, (center?.lat as number));//startX + segmentWidth;
      const coords = [
        {
          lat: this.latOffset(center?.lat as number, -halfHeight),
          lng: startX
        },
        {
          lat: this.latOffset(center?.lat as number, -halfHeight),
          lng: endX
        },{
          lat: this.latOffset(center?.lat as number, halfHeight),
          lng: endX
        },
        {
          lat: this.latOffset(center?.lat as number, halfHeight),
          lng: startX
        }
      ];
      const angles = coords.map(coord => Math.atan2(coord.lat - (center?.lat as number), coord.lng - (center?.lng as number)));
      const polygon = new google.maps.Polygon({
        paths: coords,
        strokeColor: trampoline.trampoline.colors[index],
        strokeOpacity: 1,
        fillColor: trampoline.trampoline.colors[index],
        fillOpacity: 1,
        strokeWeight: 0.25,
        zIndex: 2,
      });
      this.setPolygonEvents(polygon, trampoline.marker!);
      trampoline.polygons.push({
        polygon: polygon,
        originalCoords: coords,
        originalAngles: angles
      });
      polygon.setMap(this.map);
      if(!isNewItem)
      {
        this.rotatePolygons(trampoline.currentAngle, this.trampoline.findIndex(item => item.trampoline.id === trampoline.trampoline.id));
      }
    }
  }

  createWPRectangle(trampoline: TrampolineData, isNewItem: boolean)
  {
    const center = trampoline.marker!.position;
    const dim: RectangleDimensions = trampoline.trampoline.dimensions as RectangleDimensions;
    const segmentWidthMeters = dim.width / trampoline.trampoline.colors.length;
    const halfHeight = dim.height / 2;
    const radius = 0.000005;

    this.removeFromMap(trampoline);
    for (let index = 0; index < trampoline.trampoline.colors.length; index++)
    {
      const startX = this.lngOffset((center?.lng as number), segmentWidthMeters * index - dim.width / 2, (center?.lat as number));//(center?.lng as number) - (dim.width / 2) + (index * segmentWidth);
      const endX = this.lngOffset((center?.lng as number), segmentWidthMeters * (index + 1) - dim.width / 2, (center?.lat as number));//startX + segmentWidth;
      const coords = [
        {
          lat: this.latOffset(center?.lat as number, -halfHeight) ,
          lng: startX
        },
        {
          lat: this.latOffset(center?.lat as number, -halfHeight),
          lng: endX
        },{
          lat: this.latOffset(center?.lat as number, halfHeight),
          lng: endX
        },
        {
          lat: this.latOffset(center?.lat as number, halfHeight),
          lng: startX
        }
      ];

      const coords2 = [
        ...this.createRoundedCorners(coords[2], radius, 'ne'),
        //...this.createSide(coords[0], coords[1], 'horizontal'),
        ...this.createRoundedCorners(coords[3], radius, 'se'),
        //...this.createSide(coords[1], coords[2], 'vertical'),
        ...this.createRoundedCorners(coords[0], radius, 'sw'),
       //...this.createSide(coords[2], coords[3], 'horizontal'),
        ...this.createRoundedCorners(coords[1], radius, 'nw'),
        //...this.createSide(coords[3], coords[0], 'vertical'),
      ];

      const angles = coords2.map(coord => Math.atan2(coord.lat - (center?.lat as number), coord.lng - (center?.lng as number)));
      const polygon = new google.maps.Polygon({
        paths: coords2,
        strokeColor: trampoline.trampoline.colors[index],
        strokeOpacity: 1,
        fillColor: trampoline.trampoline.colors[index],
        fillOpacity: 1,
        strokeWeight: 0.01,
        zIndex: 2,
      });
      this.setPolygonEvents(polygon, trampoline.marker!);
      trampoline.polygons.push({
        polygon: polygon,
        originalCoords: coords2,
        originalAngles: angles
      });
      polygon.setMap(this.map);
      if(!isNewItem)
      {
        this.rotatePolygons(trampoline.currentAngle, this.trampoline.findIndex(item => item.trampoline.id === trampoline.trampoline.id));
      }
    }
  }

  createRoundedCorners(center: google.maps.LatLngLiteral, radius: number, corner: string): google.maps.LatLngLiteral[]
  {
    const points = 15;
    const angleStep = (Math.PI / 2) / points;
    const startAngle = this.getStartAngle(corner);

    const path: google.maps.LatLngLiteral[] = [];

    for(let i = 0; i <= points; i++)
    {
      const angle = startAngle + (angleStep * i);
      path.push({
        lat: center.lat + (radius * Math.sin(angle)),
        lng: center.lng + (radius * Math.cos(angle)),
      });
    }

    return path;
  }
  getStartAngle(corner: string): number
  {
    switch(corner)
    {
      case 'ne':
        return 0;
      case 'se':
        return Math.PI / 2;
      case 'sw':
        return Math.PI;
      case 'nw':
        return 3 * Math.PI / 2;
      default:
        return 0;
    }
  }
  createSide(start: google.maps.LatLngLiteral, end: google.maps.LatLngLiteral, orientation: string): google.maps.LatLngLiteral[]
  {
    const points = 20;
    const path: google.maps.LatLngLiteral[] = [];
    for(let i = 0; i <= points; i++)
    {
      const fraction = i / (points + 1);
      if(orientation === 'horizontal')
      {
        path.push({
          lat: start.lat,
          lng: start.lng + (fraction * (end.lng - start.lng))
        });
      }
      else
      {
        path.push({
          lat: start.lat + (fraction * (end.lat - start.lat)),
          lng: start.lng
        });
      }
    }
    return path;
  }

  generateDropZoneRectangle(trampoline: TrampolineData)
  {
    const center = trampoline.marker!.position;
    const dim: RectangleDimensions = trampoline.trampoline.dimensions as RectangleDimensions;
    const halfHeight = (dim.height / 2) + this.dropZoneInMeters;
    const startX = this.lngOffset((center?.lng as number), - (dim.width / 2 + this.dropZoneInMeters), (center?.lat as number));//(center?.lng as number) - (dim.width / 2) + (index * segmentWidth);
    const endX = this.lngOffset((center?.lng as number), dim.width / 2 + this.dropZoneInMeters, (center?.lat as number));//startX + segmentWidth;
    const coords = [
      {
        lat: this.latOffset(center?.lat as number, -halfHeight),
        lng: startX
      },
      {
        lat: this.latOffset(center?.lat as number, -halfHeight),
        lng: endX
      },{
        lat: this.latOffset(center?.lat as number, halfHeight),
        lng: endX
      },
      {
        lat: this.latOffset(center?.lat as number, halfHeight),
        lng: startX
      }
    ];

    const polygon = new google.maps.Polygon({
      paths: coords,
      strokeColor: 'black',
      strokeWeight: 0.75,
      strokeOpacity: 0.75,
      fillColor: 'white',
      fillOpacity: 0.1,
      zIndex: 1,
    });
    trampoline.dropZoneOriginalCoords = coords
    polygon.setMap(this.map);
    trampoline.dropZone = polygon;

  }

  setPolygonEvents(polygon: google.maps.Polygon | google.maps.Circle, marker: google.maps.marker.AdvancedMarkerElement)
  {
    google.maps.event.addListener(polygon,"mouseover",function(){
      marker!.content!.parentElement!.style.opacity = '0.8';
    });

    google.maps.event.addListener(polygon,"mouseout",() =>{
      if(!this.dragStart)
        marker!.content!.parentElement!.style.opacity = '0';
    });
  }

  latOffset(lat: number, meters: number): number{
    return lat + (meters / 111320);
  }
  lngOffset(lng: number, meters: number, lat: number): number{
    return lng + (meters / (111320 * Math.cos(lat * Math.PI / 180)));
  }


  metersToLatLng(meters: number, axis: 'lat' | 'lng', latitude?: number): number {
    const earthRadius = 6378137;
    if(axis === 'lat')
    {
      return (meters / earthRadius) * (180 / Math.PI);
    }
    else
    {
      const latRad = latitude! * (Math.PI / 180);
      return (meters / (earthRadius * Math.cos(latRad))) * (180 / Math.PI);
    }
  }
}
