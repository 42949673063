import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, map } from 'rxjs';
import * as Papa from 'papaparse';


@Injectable({
  providedIn: 'root'
})
export class LoadColorsService {

  constructor(private http: HttpClient) {

  }

  loadCSV(url: string): Observable<any[]> {
    return this.http.get(url, {
      responseType: 'text'
    }).pipe(map(csvData => {
                const parsedData = Papa.parse(csvData, {
                header: true,
                skipEmptyLines: true
              });
              return parsedData.data;
            })
          );
  }
}
