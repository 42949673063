import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  title = 'google-api';

  configVisible: boolean = true;


  toggleConfigVisible()
  {
    this.configVisible = !this.configVisible;
  }
}
