import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { Trampoline } from './item-list/trampoline';

@Injectable({
  providedIn: 'root'
})
export class ShareDataService {

  constructor() { }

  private data = new Subject<Trampoline[]>();
  data$ = this.data.asObservable();

  private centerIndex = new Subject<number>();
  centerIndex$ = this.centerIndex.asObservable();

  setData(data: Trampoline[]) {
    this.data.next(data);
  }

  centerItem(data: number) {
    this.centerIndex.next(data);
  }
}
