<div style="width: 100%; height: 100%;">
  <div class="form-group" id="searchText">
      <input #searchText type="text" (keydown.enter)="handleEnter()" placeholder="Search for places..." name="searchText"  class="form-control">
  </div>
  <div #mapContainer class="google-maps-class" id="map" width="1000px" height="1000px"></div>
  <!--<google-map #mapContainer class="google-maps-class" id="map"
    width="100%"
    height="100vh"
    [center]="center"
    [zoom]="zoom"
    [options]="options"
    (mapInitialized)="onMapInitialized($event)"

    >(centerChanged)="onCenterChanged()"-->
    <!--<map-polygon [paths]="vertices" />-->
    <!--<map-marker *ngFor="let marker of markers; let i = index"
      [position]="marker.position!"
      [draggable]="true"
      (mapDragend)="onMarkerDragEnd($event, marker)"></map-marker>
  </google-map>-->
</div>

