import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GeocodingService {

  constructor(private http: HttpClient) { }

  geocodeAdress(address: string): Observable<any>{
    const apiKey = 'AIzaSyCuT3YJMO1lHjcMdBPwOyJQOQ_E6nOLXCU';
    const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=${apiKey}`;

    return this.http.get(url);
  }

}
