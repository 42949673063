<h2>Configuration:</h2>
<div id="configPanel">
    <div class="form-group">
        <label>Number of items:</label>
        <input type="number" name="itemCount" class="form-control" [(ngModel)]="itemCount" (ngModelChange)="onItemCountChange($event)" min="0">
    </div>
    <ng-container *ngFor="let item of items">
        <div class="form-group listItem" *ngIf="item.id !== null" >
            <app-item  [item]="item" (alteredItem)="handleItemChange($event)" (removeThis)="handleItemRemoval($event)" (centerThis)="handleItemCenter($event)"></app-item>
        </div>
    </ng-container>
    <button class="btn btn-primary" [disabled]="!allIsTrue()" name="submitConfiguration" (click)="submitConfiguration()">Submit</button>
</div>
