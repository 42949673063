export interface Trampoline {
    id: number | null;
    name: string;
    type: TrampolineType;
    colorCount: number;
    centerColor: string,
    dimensions: CircleDimesions | RectangleDimensions;
    colors: string[];
}

export enum TrampolineType {
    All = "All",
    Circle = "Circle",
    Rectangle = "Rectangle",
    WPCircle = "WP Circle",
    WPRectangle = "WP Rectangle"
}

export interface CircleDimesions {
    type: "Circle";
    diameter: number;
}

export interface RectangleDimensions {
    type: "Rectangle";
    width: number;
    height: number;
}
