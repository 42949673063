import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ItemComponent } from './item/item.component';
import { ItemListComponent } from './item-list/item-list.component';
import { MapComponent } from './map/map.component';
import { GoogleMapsModule } from '@angular/google-maps';
import {GoogleMap, MapPolygon} from '@angular/google-maps';
import { CsvViewerComponent } from './csv-viewer/csv-viewer.component';
import { LoadColorsService } from './load-colors.service';
import { HttpClientModule } from  '@angular/common/http';


@NgModule({
  declarations: [
    AppComponent,
    ItemComponent,
    ItemListComponent,
    MapComponent,
    CsvViewerComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    GoogleMapsModule,
    GoogleMap, 
    MapPolygon,
    HttpClientModule
  ],
  providers: [LoadColorsService],
  bootstrap: [AppComponent]
})
export class AppModule { }
