import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Trampoline, TrampolineType } from '../item-list/trampoline';
import { CircleDimesions, RectangleDimensions } from '../item-list/trampoline';
import { TrampolineModel, ItemState } from './types';
import { LoadColorsService } from '../load-colors.service';


@Component({
  selector: 'app-item',
  templateUrl: './item.component.html',
  styleUrl: './item.component.scss'
})

export class ItemComponent {

  @Input()  item!: Trampoline;
  @Output() alteredItem = new EventEmitter<ItemState>();
  @Output() removeThis = new EventEmitter<number>();
  @Output() centerThis = new EventEmitter<number>();

  isOk: boolean = false;

  isVisible: boolean = false;
  //isOk: boolean = false;
  trampolineModel: TrampolineModel = { Typ: "", A: "", B: "", count: "0" };
  itemName = "";

  csvData: any[] = [];
  csvDataTypes: any[] = [];
  selectedColors: string[] = [];
  selectedCenterColor: string = "#FFFFFF";
  csvDataModel: any[] = [];

  dimA: number = 0;
  dimB: number = 0;

  //#region getter setter
  //getter setter Circle
  get circle(): CircleDimesions | null {
    return this.item.dimensions.type === "Circle" ? (this.item.dimensions as CircleDimesions) : null;
  }

  set circle(value: CircleDimesions | null) {
    if(value && this.item.dimensions.type === "Circle")
    {
      this.item.dimensions = value;
    }
  }

  //getter setter Rectangle
  get rectangle(): RectangleDimensions | null {
    return this.item.dimensions.type === "Rectangle" ? (this.item.dimensions as RectangleDimensions) : null;
}

  set rectangle(value: RectangleDimensions | null) {
    if(value && this.item.dimensions.type === "Rectangle")
    {
      this.item.dimensions = value;
    }
  }
  //#endregion

  public TrampolineTypes = TrampolineType;
  trampolineSelectType = "All";

  constructor(private loadColor: LoadColorsService){}

  ngOnInit(): void {
    const csvUrlColor = './assets/ral_classic.csv';
    const csvUrlTypes = './assets/trampoline_types.csv';

    this.loadColor.loadCSV(csvUrlColor).subscribe(
      (data: any[]) => {
        this.csvData = data;
      }
    );

    this.loadColor.loadCSV(csvUrlTypes).subscribe(
      (data: any[]) => {
        this.csvDataTypes = data;
        this.csvDataModel = this.csvDataTypes;
        this.trampolineModel = this.csvDataModel[0];
        this.changeType();
        this.dimA = parseFloat(this.trampolineModel.A.replace(/,/g, '.'));
        this.dimB = parseFloat(this.trampolineModel.B.replace(/,/g, '.'));
        
        //this.item = this.csvDataModel[0];
        this.addSelectedColor();
      }
    );
    
    this,this.itemName = this.item.name;
  }

  toggleDiv()
  {
    this.isVisible = !this.isVisible;
  }

  removeItem()
  {
    this.removeThis.emit(this.item.id as number);
  }

  refreshDimItem()
  {
    this.dimA = parseFloat(this.trampolineModel.A.replace(/,/g, '.'));
    this.dimB = parseFloat(this.trampolineModel.B.replace(/,/g, '.'));
    this.onUpdateObject();
  }

  centerItem()
  {
    if(this.isOk)
      this.centerThis.emit(this.item.id as number);
    this.isVisible = !this.isVisible;
    //console.log("emit item");
  }

  onUpdateObject(){
    if(this.item.type === TrampolineType.Circle || this.item.type === TrampolineType.WPCircle)
      (this.item.dimensions as CircleDimesions).diameter = this.dimA;
    if(this.item.type === TrampolineType.Rectangle || this.item.type === TrampolineType.WPRectangle)
    {
      (this.item.dimensions as RectangleDimensions).width = this.dimA;
      (this.item.dimensions as RectangleDimensions).height = this.dimB;
    }
    this.isOk = !Object.values(this.item).some(value =>
      value === null ||
      value === undefined ||
      value === '' ||
      (Array.isArray(value) && value.some(item =>
        typeof item === 'string' &&
        item.length === 0
      )) ||
      (Array.isArray(value) && value.length === 0)
    );
    this.itemName = this.item.name;
    if(this.itemName.length < 1)
    {
      this.itemName = "Name your item!";
    }
    if(this.isOk)
      this.alteredItem.emit({state: this.isOk, id: (this.item.id as number)});
    
    //console.log(this.isOk + " - " + this.item.colorCount);
    //console.log(this.item);
  }

  onUpdateSelectedType(){
    if(this.trampolineSelectType === TrampolineType.All)
    {
      //this.item.dimensions.type = "Circle";
      this.csvDataModel = this.csvDataTypes;
    }
    else if(this.trampolineSelectType === TrampolineType.Circle)
    {
      //this.item.dimensions.type = "Circle";
      this.csvDataModel = this.csvDataTypes.filter(item => item['B'].length == 0 && !item['Typ'].includes("WP"));
    }
    else if(this.trampolineSelectType === TrampolineType.Rectangle)
    {
      //this.item.dimensions.type = "Rectangle";
      this.csvDataModel = this.csvDataTypes.filter(item => item['B'] && !item['Typ'].includes("WP"));
    }
    else if(this.trampolineSelectType === TrampolineType.WPCircle)
    {
      //this.item.dimensions.type = "Circle";
      this.csvDataModel = this.csvDataTypes.filter(item => item['B'].length == 0 && item['Typ'].includes("WPR") );
    }
    else if(this.trampolineSelectType === TrampolineType.WPRectangle)
    {
      //this.item.dimensions.type = "Rectangle";
      this.csvDataModel = this.csvDataTypes.filter(item => item['B'] && item['Typ'].includes("WP") && !item['Typ'].includes("WPR"));
    }
    
    this.trampolineModel = this.csvDataModel[0];
    this.changeType();
    this.addSelectedColor();
  }

  getTrampolineType(): TrampolineType
  {
    //console.log(this.trampolineModel.count);
    if(this.trampolineModel.B.length > 0)
    {
      if(this.trampolineModel.count === '1')
        return  TrampolineType.WPRectangle;
      else
        return TrampolineType.Rectangle;
    }
    else
    {
      if(this.trampolineModel.count === '1')
        return TrampolineType.WPCircle
      else
        return TrampolineType.Circle;
    }
  }

  changeType(){
    this.item.type = this.getTrampolineType();
    if(this.trampolineModel.B.length > 0)
    {
      this.dimA = parseFloat(this.trampolineModel.A.replace(/,/g, '.'));
      this.dimB = parseFloat(this.trampolineModel.B.replace(/,/g, '.'));
      const dim: RectangleDimensions = {
        type: 'Rectangle',
        width: this.dimA,
        height: this.dimB
      }
      this.item.dimensions = dim;
    }
    else
    {
      this.dimA = parseFloat(this.trampolineModel.A.replace(/,/g, '.'));
      const dim: CircleDimesions = {
        type: 'Circle',
        diameter: this.dimA
      }
      this.item.dimensions = dim;
    }
  }

  addSelectedColor(){
    //this.selectedColors = [];
    let diff = parseInt(this.trampolineModel.count) - this.selectedColors.length;
    if(this.selectedColors.length <= parseInt(this.trampolineModel.count))
    {
      for (let index = 0; index < diff; index++) {
        this.selectedColors.push("");
      }
    }
    else
    {
      this.selectedColors.splice(-(this.selectedColors.length - parseInt(this.trampolineModel.count)), (this.selectedColors.length - parseInt(this.trampolineModel.count)));
    }
  }

  getNumberArray(num: string): number[] {
      return Array(parseInt(num)).fill(0).map((x, i) => i);
  }

  onCenterColorChange(event: Event){
    const selectElement = event.target as HTMLSelectElement;
    if(selectElement && selectElement.value)
    {
      this.selectedCenterColor = selectElement.value;
      this.item.centerColor = selectElement.value;
    }
    this.onUpdateObject();
  }

  onColorChange(event: Event, index: number){
    const selectElement = event.target as HTMLSelectElement;
    if(selectElement && selectElement.value)
    {
      if(this.selectedColors.length > index)
      {
        this.selectedColors[index] = selectElement.value;
      }
      this.item.colors = [];
      this.item.colors = this.selectedColors;
    }
    this.onUpdateObject();
  }

  onModelChange(event: Event)
  {
    const selectElement = event.target as HTMLSelectElement;
    this.trampolineModel = this.csvDataTypes.find(model => model.Typ === selectElement.value);
    this.item.type = this.getTrampolineType();
    if(this.trampolineModel.B.length > 0)
    {
      this.dimA = parseFloat(this.trampolineModel.A.replace(/,/g, '.'));
      this.dimB = parseFloat(this.trampolineModel.B.replace(/,/g, '.'));
      this.item.dimensions.type = 'Rectangle';
      const dim: RectangleDimensions = {
        type: 'Rectangle',
        width: this.dimA,
        height: this.dimB
      }
      this.item.dimensions = dim;
    }
    else
    {
      this.dimA = parseFloat(this.trampolineModel.A.replace(/,/g, '.'));
      this.item.dimensions.type = 'Circle';
      const dim: CircleDimesions = {
        type: 'Circle',
        diameter: this.dimA
      }
      this.item.dimensions = dim;
    }
    console.log(this.item.type);
    this.addSelectedColor();
    //console.log(this.trampolineModel);
    this.onUpdateObject();
    
  }
}
