import { Component, EventEmitter, Output } from '@angular/core';
import { Trampoline, TrampolineType, CircleDimesions, RectangleDimensions } from './trampoline';
import { ShareDataService } from '../share-data.service';
import { ItemState } from '../item/types';


type Shape = CircleDimesions | RectangleDimensions;

@Component({
  selector: 'app-item-list',
  templateUrl: './item-list.component.html',
  styleUrl: './item-list.component.scss'
})

export class ItemListComponent {

  itemCount: number = 0;
  itemsCountLast: number = 0;
  items: Trampoline[] = [];
  shape!: Shape;
  isOk: boolean[] = [];


  constructor(private dataService: ShareDataService){

  }

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.

  }

  onItemCountChange(newValue: Event){
    //this.items = [];
    //console.log(newValue, this.itemCount, this.itemsCountLast);
    if(this.itemCount > 0 && this.itemsCountLast >= 0)
    {
      for (let index = this.itemsCountLast; index < this.itemCount; index++) {
        this.shape = {
          type: "Rectangle",
          width: 1,
          height: 1
        };

        this.items.push({
          id: this.items.length,
          name: "item " + this.items.length,
          type: TrampolineType.Circle,
          colorCount: 1,
          centerColor: "#FFFFFF",
          dimensions: this.shape,
          colors: []
        });
        if(index === null || index === undefined)
          {
            console.log("Error during new data creation");
            console.log(this.items[this.items.length-1]);
          }
        this.isOk.push(false);
      }
    }
    if(this.itemsCountLast > this.itemCount && this.itemCount > 0)
    {
      console.log(this.items);
      this.items.splice(-(this.itemsCountLast - this.itemCount), (this.itemsCountLast - this.itemCount));
      this.isOk.splice(-(this.itemsCountLast - this.itemCount), (this.itemsCountLast - this.itemCount));
      while (this.items[this.items.length - 1].id === null) {
        this.items.pop();
        this.isOk.pop();  
        //console.log("Remove - new:" + this.items[this.items.length - 1].id);
      }
    }
    if(this.itemCount === null || this.itemCount === undefined || this.itemCount <= 0)
    {
      this.itemCount = 0;
      this.items = [];
      this.isOk = [];
    }
    this.itemsCountLast = this.itemCount;
    console.log(this.items);
    //console.log(this.itemCount);
  }

  submitConfiguration() {
    this.dataService.setData(this.items);
  }

  handleItemChange(data: ItemState){
    this.isOk[data.id] = data.state;
    //console.log(updatedItem);
  }

  handleItemCenter(index: number)
  {
    this.dataService.centerItem(index);
  }

  handleItemRemoval(index: number){
    //console.log(data.id + " --- " + this.items[this.items.length - 1].id);
    if(index === this.items[this.items.length - 1].id)
    {
      this.items[index].id = null;
      //console.log("removing");
      this.itemCount--;
      this.itemsCountLast--;
      if(this.items.length > 1)
      {
        while (this.items[this.items.length - 1].id === null) {
          this.items.pop();
          this.isOk.pop();  
          //console.log("Remove - new:" + this.items[this.items.length - 1].id);
        }
      }
      //console.log(this.items.length);
    }
    else
    {
      this.isOk[index] = true;
      this.items[index].id = null;
      this.itemCount--;
      this.itemsCountLast--;
    }
    let ok = true;
    console.log(this.items);
    //check if data are set everywhere
    this.items.forEach(item => {
      if(item.id === null)
        ok = false;
      else if(!this.isOk[item.id])
        ok = false;
    });
    //if all ok send it as data
    if(ok && this.items.length > 0)
    {
      this.dataService.setData(this.items);
      console.log(this.items);
    }
    else if(this.items.every(x => x.id === null))
    {
      this.items = [];
      this.itemCount = 0;
      this.itemCount = 0;
      this.isOk = [];
      console.log("all removed");
    }
    //console.log(updatedItem);
  }

  allIsTrue(): boolean
  {
    return this.isOk.every(item => item);
  }
}

